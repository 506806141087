export const es_ES = {
  customer_type: "Tipo de cliente",
  manage_appointment_now: "Gestionar cita ahora",
  maintenance_page_title: "Calenso sólo se está mejorando para ti",
  maintenance_page_subtitle: "Calenso se está actualizando a la última versión. Por favor, ten paciencia y vuelve a comprobarlo en unos minutos.",
  maintenance_page_button: "A la página de estado",
  load_more: "Cargar más",
  show_more: "Mostrar más",
  internal_label: "Interno",
  check_availability_for_anyone: "Comprueba la disponibilidad de cualquier persona",
  store_not_found: "No se encontró ninguna tienda",
  appointment: "Citas",
  event: "Citas de grupo",
  resource: "Recursos",
  morgen: "por la mañana",
  vormittag: "por la mañana",
  mittag: "mediodía",
  nachmittag: "por la tarde",
  abend: "por la noche",
  nacht: "noche",
  free: "gratis",
  hoursShort: "",
  minutesShort: "m",
  mondayLong: "Lunes",
  day1: "LUN",
  tuesdayLong: "Martes",
  day2: "MAR",
  wednesdayLong: "Miércoles",
  day3: "MIÉ",
  thursdayLong: "Jueves",
  day4: "JUE",
  fridayLong: "Viernes",
  day5: "VIE",
  saturdayLong: "Sábado",
  day6: "SAB",
  sundayLong: "Domingo",
  day0: "DOM",
  Januar: "Enero",
  Februar: "Febrero",
  März: "Marzo",
  April: "Abril",
  Mai: "Mayo",
  Juni: "Junio",
  Juli: "Julio",
  August: "Agosto",
  September: "Septiembre",
  Oktober: "Octubre",
  November: "Noviembre",
  Dezember: "Diciembre",
  "shortMonths": {
    "Januar": "Ene",
    "Februar": "Feb",
    "März": "Mar",
    "April": "Abr",
    "Mai": "May",
    "Juni": "Jun",
    "Juli": "Jul",
    "August": "Ago",
    "September": "Sep",
    "Oktober": "Oct",
    "November": "Nov",
    "Dezember": "Dic"
  },
  du: "tú",
  sie: "Tú",
  havingFormDu: "tienen",
  havingFormSie: "tienen",
  yes: "Sí",
  no: "No",
  confirm: {
    title: "Confirmar pedido",
    confirmationText: "Tienes {{ cartLength }} citas en tu cesta. ¿Realmente quieres reservarlas todas ahora?",
    confirmationTextSie: "Tienes {{ cartLength }} citas en tu cesta. ¿Realmente quieres reservarlas todas ahora?",
    ok: "Libro",
    cancel: "Cancela"
  },
  internal: {
    search_customer: "Buscar clientes existentes y rellenar el formulario",
    selectPlaceholder: "Buscar cliente",
    customerDetails: "Datos del cliente",
    noCustomer: "No hay clientes configurados",
    create_customer: "Crear cliente"
  },
  common: {
    report_bug: "Informar de un error",
    subscription_error_title: "¡No hay suscripción!",
    subscription_error_desc: "Lo sentimos, no se ha encontrado ninguna suscripción. Por favor, ponte en contacto con el servicio de asistencia de Calenso.",
    next: "Siguiente",
    previous: "Anteriormente",
    reset: "Restablecer la aplicación",
    resetSuccess: "El software de reservas se ha restablecido con éxito. Los datos que se almacenaron temporalmente han desaparecido.",
    loading: "Cargar ...",
    durationHour: "h",
    durationMinute: "m",
    clock: "h",
    disclaimer: "Este servicio lo presta <a href='https://www.calenso.com' class='cal-link' target='_blank'>Calenso</a>.",
    book: "Libro",
    choseBookType: "¿Qué te gustaría reservar?",
    company: "Empresa",
    prename: "Nombre",
    lastname: "Apellido",
    email: "Correo electrónico",
    phone: "Teléfono",
    address: "Dirección",
    createAccount: "Crea una cuenta de cliente (cuenta de usuario con todas las inscripciones)",
    noDateError: "La cita deseada fue reservada por otra persona, vuelva atrás y seleccione una nueva cita.",
    devNotice: "El widget de reserva está conectado al sistema de backend de DEV. ¡Ten cuidado!",
    store_access_warning: 'El acceso a la memoria del navegador está restringido debido a sus configuraciones de privacidad. Es posible que no estén disponibles todas las funciones del widget de reserva.',
    save: "Guardar",
    cancel: "Cancelar",
    additionalParticipantsUpTo: "Puedes agregar hasta {{ number }} invitados",
    additionalGuest: "Participantes adicionales",
    additionalGuestCartLabel: "{{ number }} invitados adicionales",
    widget_disabled: "El widget de reservas está actualmente desactivado",
    widget_disabled_description: "El widget de reservas se ha desactivado temporalmente debido a problemas de pago. Si tienes alguna duda, ponte en contacto con el servicio de asistencia de Calenso.",
    no_workers_widget_disabled_description: "Actualmente no se pueden reservar citas porque no hay ningún recurso habilitado.",
    workers_not_available: "No se ha encontrado ningún servicio reservable",
    workers_not_available_description: "No se ha registrado ningún servicio reservable. Asegúrate de que al menos un servicio está disponible y asignado a un recurso reservable (capacidad). Introducir una categoría de servicio no es suficiente para ser reservable.",
    addAdditionalGuest: "Añadir participantes",
    additionalGuestsCartLabel: "{{ número }} invitados adicionales",
    price_from_label: "de",
    close: "Cerrar",
    configuration_error: "Error de configuración",
    resource_is_not_bookable: "El recurso seleccionado no se puede reservar.",
    verification_token_missing: "El widget de reservas se utiliza en modo interno y, por tanto, requiere un token de verificación. Póngase en contacto con el servicio de asistencia de Calenso.",
    invalid_verification_code: "El acceso al widget en modo interno requiere un token válido. Asegúrate de que el token sea válido e inténtalo nuevamente.",
    internal_appointment_booking_widget_disabled: "El servicio de widget interno ha sido desactivado por el socio. Por favor, habla con tu administrador del sistema para más información.",
    external_appointment_booking_widget_disabled: "El servicio de widget ha sido desactivado por nuestro socio. Contáctalos para más información.",
    external_event_booking_widget_disabled: "El servicio de widget ha sido desactivado por nuestro socio. Contáctalos para más información.",
    conference_invalid_link: "Parece que el enlace de la conferencia proporcionado no es válido. Por favor, verifica el enlace e intenta nuevamente.",
    conference_expired_link: "Lo sentimos, pero este enlace de conferencia ha caducado. Por favor, contacta al organizador para obtener más ayuda.",
    conference_not_belonging: "¡Ups! Parece que esta conferencia está vinculada a otro socio.",
    conference_booked: "¡Ups, parece que este enlace de conferencia ya ha sido utilizado y reservado! Si tienes alguna pregunta o necesitas ayuda, no dudes en ponerte en contacto con los organizadores.",
    conference_invalid_configurations: "La conferencia no está disponible debido a configuraciones internas no válidas. Para más ayuda, ponte en contacto con el organizador.",
    location: "Ubicación",
    empty_string_validation: "Solo espacios no están permitidos.",
    invalid_password: "Por favor, introduzca una contraseña válida."
  },
  appointments: {
    step1: {
      distance_from_store: "{{ distance }} km de distancia",
      search_store_postcode_helper_text: "Introduce el código postal para buscar la tienda más cercana",
      search_services_helper_text: "Introduce un término de búsqueda para buscar servicios",
      title: "Servicios",
      chooseStore: "Selecciona la tienda",
      chooseService: "Selecciona el servicio",
      nextButton: "Siguiente",
      search_store_by_postcode_placeholder: "Introduce el código postal para buscar la tienda correcta",
      create_lead: "Oportunidad MH",
      expert_cap_button_label: "Asignar a un experto CAP",
      appointment_booking_not_available_for_zipcode: "Lo sentimos, la reserva de citas no está disponible para este código postal. Crea un nuevo cliente para que un CAP pueda contactar con él por teléfono."
    },
    step2: {
      title: "Recurso",
      chooseEmployee: "Elige un recurso gratuito",
      name: "Nombre",
      function: "Función",
      noSpecificWorker: "Cualquiera",
      noSpecificFunction: "Todas las funciones",
      exception: "Definir la excepción de empleado",
      nextButton: "Elige la fecha/hora"
    },
    step3: {
      title: "Fecha",
      chooseDate: "Elige la fecha preferida",
      morning: "Por la mañana",
      forenoon: "Por la mañana",
      noon: "Mediodía",
      afternoon: "Por la tarde",
      evening: "Por la noche",
      night: "Noche",
      infoMsg: "Ten en cuenta que es posible seleccionar varias citas. Sólo tienes que elegir las citas que desees a través de la casilla de verificación. A continuación, las citas se añaden a la cesta.",
      appointment: "Cita",
      employee: "Recurso",
      proposals: "Citas gratuitas",
      noFreeAppointments: "Lamentablemente, ese día no hay citas libres.",
      nextButton: "Siguiente",
      cartNotSupported: "Este proveedor sólo admite el registro de una ranura por reserva.",
      cartSupported: "Este proveedor admite varias inscripciones por reserva.",
      workerSelectLable: "Seleccionar Asesor Personal:",
      workerSelectLinkPrefix: "¿No encontraste una cita con tu asesor?",
      workerSelectShowAllLinkPostfix: "Mostrar todos los asesores",
      workerSelectHideAllLinkPostfix: "Ocultar todos los asesores",
      troubleshooting_mode_note_pre: "¿Falta un horario? Prueba ",
      troubleshooting_mode_link: "el modo de solución de problemas",
      troubleshooting_mode_note_post: " y obtén más información sobre los horarios no disponibles.",
      turn_off_troubleshooting: "Desactivar",
      common_notes: "Notas comunes"
    },
    step4: {
      comment_helper_text: "Máximo 2000 caracteres",
      title: "Acabado",
      summary: "Resumen",
      company: "Empresa",
      store: "Tienda",
      employee: "Recurso",
      bookedServices: "Servicio",
      duration: "Duración",
      price: "Precio",
      finalPrice: "Costes",
      total: "Total",
      yourData: "Tus datos",
      disclaimer: "Por favor, introduce tus datos de contacto personales. Todos los campos marcados con * son obligatorios.",
      prename: {
        label: "Prenombre",
        placeholder: "Prenombre",
        error: {
          minChars: "El primer nombre debe contener al menos {{ requiredLength }} caracteres",
          required: "Por favor, introduce un nombre de pila"
        }
      },
      lastname: {
        label: "Apellido",
        placeholder: "Apellido",
        error: {
          minChars: "El apellido debe contener al menos {{ requiredLength }} caracteres",
          required: "Por favor, introduce un apellido"
        }
      },
      phone: {
        label: "Teléfono móvil",
        placeholder: "+4179xxxxxxx",
        error: {
          pattern: "El número de teléfono sólo puede contener dígitos",
          minChars: "El número de teléfono debe contener al menos {{ requiredLength }} dígitos",
          required: "Por favor, introduce un número de teléfono"
        }
      },
      eMail: {
        label: "Envía un correo electrónico a",
        error: {
          pattern: "Por favor, introduce una dirección de correo electrónico válida",
          minChars: "La dirección de correo electrónico debe contener al menos {{ requiredLength }} caracteres",
          required: "Por favor, introduce una dirección de correo electrónico"
        }
      },
      message: {
        label: "Mensaje",
        placeholder: ""
      },
      customFields: {
        defaultOption: "Por favor, elige una opción",
        error: {
          maxChars: 'Este campo permite un máximo de {{ requiredLength }} caracteres',
          minChars: "Este campo requiere al menos {{ requiredLength }} caracteres",
          required: "Este campo es obligatorio",
          questionisRequired: "{{ text }} es obligatorio",
          checkboxisRequired: "Esta casilla de verificación debe estar seleccionada para continuar."
        }
      },
      internal_comment: {
        label: "Comentario interno",
        placeholder: "Añadir comentarios para uso interno",
        comment_helper_text: "Máximo 5000 caracteres."
      },
      company_name: {
        label: "Nombre de la empresa",
        error: {
          minChars: "Este campo requiere al menos {{ requiredLength }} caracteres."
        }
      },
      cart: {
        emptyCartWarning: "Actualmente no hay citas en tu cesta",
        removeIcon: "Eliminar la cita de la cesta",
        waiting_list: "Lista de espera"
      },
      numberSelect: {
        increase: 'Aumentar valor',
        decrease: 'Disminuir valor'
      }
    },
    finalpage: {
      successTitle: "Has reservado con éxito la(s) cita(s)",
      successMessageDu: "La reserva de la cita se transmitió con éxito. Se ha enviado una confirmación por correo electrónico o por SMS. <br> <br> Si no puede asistir al acto por cualquier motivo, cancele su participación a través del enlace de cancelación que encontrará en el correo electrónico o mensaje de texto.",
      successMessageSie: "La reserva de la cita se transmitió con éxito. Se ha enviado una confirmación por correo electrónico o por SMS. <br> <br> Si no puede asistir al acto por cualquier motivo, cancele su participación a través del enlace de cancelación que encontrará en el correo electrónico o mensaje de texto.",
      errorTitle: "Se ha producido un error.",
      errorMessage: "La fecha no pudo ser reservada. Por favor, inténtalo de nuevo.",
      errorMessageDu: 'Der Termin konnte leider nicht gebucht werden. Bitte versuch es erneut.',
      errorMessageSie: 'Der Termin konnte leider nicht gebucht werden. Bitte versuchen Sie es erneut.',
      bookNextAppointment: "Reservar cita adicional",
      bookAppointmentAgain: "Volver"
    },
    error: {
      noworkers: "Lamentablemente, este proveedor no ha definido todavía ningún recurso reservable."
    }
  },
  events: {
    list: "Lista",
    grid: "Rejilla",
    noEvents: "Este proveedor aún no ha especificado ningún evento.",
    hidden_events_message: "Los eventos de este socio están ocultos. Por favor, utiliza el enlace directo al evento que has recibido del socio para inscribirte.",
    step1: {
      title: "Eventos",
      moreInformation: "Más información",
      noFreeSlots: "No más tragamonedas gratis",
      noEvents: "No se han creado eventos.",
      showDescription: "Mostrar toda la descripción",
      hideDescription: "Ocultar descripción",
      book: "Libro",
      noPlaceDefined: "No hay lugar definido",
      store_filter: "Filtro de la tienda",
      category_filter: "Filtro de categoría",
      all_categories: "Todos"
    },
    step2: {
      title: "Fecha",
      slots: "Ranuras",
      chooseDate: "Elige la fecha preferida",
      booked: "Reservado",
      capacity: "Disponible",
      noFreeDates: "No hay eventos gratuitos para este socio",
      bookedOut: "Este espacio ya está reservado",
      cartNotSupported: "Este proveedor sólo admite el registro de una ranura por reserva.",
      cartSupported: "Este proveedor admite varias inscripciones por reserva.",
      alreadyBookedByUser: "Ya has reservado este espacio. En caso de problemas, reinicia la aplicación.",
      waitinglist: "lista de espera: {{ capacity }} libre",
      fully_booked: "Completamente reservado",
      regular_count_singular: "<b>1</b> asiento libre",
      regular_count_plural: "<b>{{ number }}</b> plazas libres",
      waiting_count_singular: "1 lugar libre en la lista de espera",
      waiting_count_plural: "{{ number }} lugares libres en la lista de espera",
      free_seats: "Asientos libres",
      free_waiting_seats: "Plazas gratuitas en lista de espera",
      multiday_group_appointment: "Esta es una cita grupal de varios días"
    },
    step3: {
      title: "Acabado",
      summary: "Resumen",
      company: "Empresa",
      store: "Tienda",
      employee: "Recurso",
      bookedEvent: "Evento",
      date: "Fecha",
      time: "Tiempo",
      price: "Precio",
      finalPrice: "Costes",
      total: "Total",
      yourData: "Tus datos",
      disclaimer: "Introduce tus datos de contacto. Todos los campos marcados con * son obligatorios.",
      cart: {
        emptyCartWarning: "Actualmente no hay eventos en el carro.",
        waiting_list: "Lista de espera"
      }
    },
    finalpage: {
      successTitle: "El registro fue enviado con éxito.",
      successMessageDu: "La inscripción al evento fue enviada con éxito. Se ha enviado una confirmación por correo electrónico o por SMS. <br> <br> Si no puede asistir al acto por cualquier motivo, cancele su participación a través del enlace de cancelación que encontrará en el correo electrónico o mensaje de texto.",
      successMessageSie: "La inscripción al evento fue enviada con éxito. Se ha enviado una confirmación por correo electrónico o por SMS. <br> <br> Si no puede asistir al acto por cualquier motivo, cancele su participación a través del enlace de cancelación que encontrará en el correo electrónico o mensaje de texto.",
      errorTitle: "Se ha producido un error.",
      errorMessage: "Se ha producido un error durante el registro. Por favor, inténtalo de nuevo.",
      bookNextEvent: "Reservar más inscripciones",
      bookEventAgain: "Volver"
    }
  },
  resources: {
    noResources: "Este proveedor aún no ha especificado ningún recurso.",
    step1: {
      title: "Recursos",
      chooseResource: "Elige los recursos"
    },
    step2: {
      title: "Fecha",
      startDate: "Fecha de inicio: ",
      endDate: "Fecha de finalización: ",
      startTime: "Por favor, selecciona la hora de inicio",
      endTime: "Selecciona la hora de finalización",
      resetCalendar: "Reajustar el calendario",
      noFreeResources: "No se han encontrado recursos reservables para daterange",
      selectDate: "Selecciona la fecha o el rango de fechas"
    },
    step3: {
      title: "Acabado",
      summary: "Resumen",
      company: "Empresa",
      store: "Tienda",
      employee: "Recurso",
      bookedEvent: "Evento",
      date: "Fecha",
      time: "Tiempo",
      price: "Precio",
      finalPrice: "Costes",
      total: "Total",
      yourData: "Tus datos",
      disclaimer: "Introduce tus datos de contacto. Todos los campos marcados con * son obligatorios.",
      prename: {
        label: "Nombre",
        placeholder: "Nombre",
        error: {
          minChars: "El primer nombre debe tener al menos {{ requiredLength }} caracteres",
          required: "Por favor, indica tu nombre"
        }
      },
      lastname: {
        label: "Apellido",
        placeholder: "Apellido",
        error: {
          minChars: "El apellido debe tener al menos {{ requiredLength }} caracteres",
          required: "Por favor, introduce los apellidos"
        }
      },
      phone: {
        label: "Teléfono móvil",
        placeholder: "+4179xxxxxxx",
        error: {
          pattern: "El número de teléfono sólo puede contener números",
          minChars: "El número de teléfono debe ser como mínimo {{ requiredLength }} Contener caracteres",
          required: "Por favor, introduce un número de teléfono"
        }
      },
      eMail: {
        label: "Correo electrónico",
        error: {
          pattern: "Por favor, introduce una dirección de correo electrónico válida",
          minChars: "La dirección de correo electrónico debe tener al menos {{ requiredLength }} caracteres",
          required: "Por favor, introduce una dirección de correo electrónico"
        }
      },
      message: {
        label: "Mensaje",
        placeholder: ""
      },
      customFields: {
        defaultOption: "Elige cualquier opción",
        error: {
          minChars: "Este campo requiere {{ requiredLength }} caracteres",
          required: "Este campo es obligatorio"
        }
      },
      cart: {
        emptyCartWarning: "Actualmente no hay recursos en el carro."
      },
      From: "Desde",
      To: "A"
    },
    finalpage: {
      successTitle: "Has reservado con éxito los recursos",
      successMessageDU: "En caso de que cambies de opinión, cancela tu reserva de recursos al menos {{ leadTime }} horas antes de reservarlos.",
      successMessageSIE: "En caso de que cambies de opinión, cancela tu reserva de recursos al menos {{ leadTime }} horas antes de reservarlos.",
      errorTitle: "Se ha producido un error.",
      errorMessage: "No se ha podido reservar el recurso. Por favor, inténtalo de nuevo.",
      bookNextEvent: "Reservar recursos adicionales",
      bookEventAgain: "Volver"
    }
  },
  customer: {
    prename: {
      label: "Nombre",
      placeholder: "Nombre",
      error: {
        minChars: "El nombre debe tener al menos {{ requiredLength }} caracteres",
        required: "Por favor, introduce tu nombre"
      }
    },
    lastname: {
      label: "Apellido",
      placeholder: "Apellido",
      error: {
        minChars: "El apellido debe tener al menos {{ requiredLength }} caracteres",
        required: "Por favor, introduce tu apellido"
      }
    },
    phone: {
      label: "Número de teléfono",
      placeholder: "+4179.......",
      error: {
        pattern: "El número de teléfono debe contener sólo números",
        minChars: "El número de teléfono debe contener al menos {{ requiredLength }} números",
        required: "Por favor, introduce tu número de teléfono",
        invalid: "Por favor, introduzca un número de móvil válido."
      }
    },
    eMail: {
      label: "Envía un correo electrónico a",
      error: {
        pattern: "Por favor, introduce una dirección de correo electrónico válida",
        minChars: "La dirección de correo electrónico debe contener al menos {{ requiredLength }} caracteres",
        required: "Se requiere una dirección de correo electrónico"
      }
    },
    street: {
      label: "Calle",
      placeholder: "Nombre de la calle",
      error: {
        minChars: "La calle debe contener al menos {{ requiredLength }} caracteres",
        required: "Por favor, introduce el nombre de tu calle"
      }
    },
    zip: {
      label: "ZIP",
      placeholder: "Código postal",
      error: {
        minChars: "El número postal debe contener al menos {{ requiredLength }} caracteres",
        required: "Por favor, introduce tu número postal",
        pattern: "Por favor, ingresa un código postal válido.",

      }
    },
    city: {
      label: "Ciudad",
      placeholder: "Nombre de la ciudad",
      error: {
        minChars: "La ciudad debe contener al menos {{ requiredLength }} caracteres",
        required: "Por favor, introduce el nombre de tu ciudad"
      }
    },
    message: {
      label: "Mensaje",
      placeholder: ""
    },
    customFields: {
      defaultOption: "Selecciona una opción",
      error: {
        minChars: "Este campo requiere al menos {{ requiredLength }} caracteres",
        required: "Este campo es obligatorio"
      }
    },
    captcha: {
      required: "Este campo es obligatorio.",
      invalid: "Por favor, introduzca un captcha válido.",
      valid: "Captcha encontrado correctamente.",
      placeholder: "Escriba el texto que aparece arriba"
    }
  },
  stripe: {
    stripe_title: "Paga tu próxima visita en línea",
    submit_payment: "Enviar el pago",
    stripe_label: "Tarjeta de crédito o de débito",
    online_payment_optional: "En este paso, tienes la oportunidad de liquidar tu próxima visita ahora mismo. No hay cargos adicionales para ti. El pago se realiza a través de un proveedor seguro (Stripe). No almacenamos la información de la tarjeta de crédito en nuestros servidores.",
    online_payment_mandatory: "Para completar la reserva, te pedimos que pagues ahora tu próxima visita. No hay cargos adicionales para ti. El pago se realiza a través de un proveedor seguro (Stripe). No almacenamos la información de la tarjeta de crédito en nuestros servidores."
  },
  alert_messages: {
    select_day_in_calendar_msg: "Selecciona un día concreto en el calendario para que te sugieran posibles fechas.",
    no_events_in_month: "Lamentablemente, no hay más franjas horarias libres en el mes seleccionado.",
    no_events: "Lamentablemente, este evento no tiene ventanas reservables en el futuro. Por lo tanto, no se muestra ningún calendario para seleccionar una fecha.",
    waitinglist_enabled_message: "Algunas franjas horarias ya están reservadas, pero aún puedes inscribirte en la lista de espera. En cuanto alguien cancele su participación, pasarás automáticamente a la lista y recibirás una confirmación de participación.",
    waitinglist_booking_message: "¡Muchas gracias! Hemos aceptado con éxito tu reserva. Como ahora estás en la lista de espera para al menos un evento, sólo recibirás una confirmación de participación por nuestra parte si otro participante no puede asistir al evento. En este caso, se te notificará por correo electrónico. Lamentablemente, debido a la falta de espacio, no podrás asistir al evento sin una confirmación oficial de nuestro sitio.",
    window_is_fully_booked: "Esta franja horaria está totalmente reservada (incluida la lista de espera).",
    singular_waitinglist_is_available: "Este horario está completo, pero aún tiene <b>{{ number }}</b> plaza libre en la lista de espera.",
    plural_waitinglist_is_available: "Este horario está completo, pero aún tiene <b>{{ number }}</b> plazas libres en la lista de espera.",
    waitinglist_is_available: "Esta franja horaria está totalmente reservada, pero todavía tiene <b>{{ number }}</b> plazas disponibles en la lista de espera.",
    no_bookable_dates_message: "Lamentablemente no hay más días reservables en el mes {{ month }}. ¿Debemos buscar fechas libres en meses futuros?",
    no_slots_dates_message: "Lamentablemente, no hay más franjas horarias reservables en el mes {{ month }}. ¿Debemos buscar franjas horarias libres en meses futuros?",
    no_slots_dates_reached_message: "Lamentablemente no hay más franjas horarias reservables en el mes {{ month }}. Se ha alcanzado el periodo máximo de reserva.",
    no_slots_years_reached_message: "Lamentablemente, no hay más franjas horarias disponibles para el año {{ year }}. Se ha alcanzado el período máximo de reserva.",
    no_bookable_dates_reached_message: "Lamentablemente no hay más fechas reservables en el mes {{ month }}. Se ha alcanzado el periodo máximo de reserva.",
    xss_error_message: "Este texto contiene etiquetas HTML no compatibles.",
    slot_already_reserved_by_other_error: "Mientras tanto, el horario que intentabas reservar ya ha sido reservado por otra persona. Por favor, elige otro horario y vuelve a intentarlo más tarde.",
    failed_to_reserve_slot_error: "Algo salió mal al intentar reservar el horario para ti. ¡Inténtalo de nuevo más tarde!",
    reserved_by_someone: "Reservado por otra persona"
  },
  coupon_credit_card_info: {
    redeem_coupon_1: "Canjear el cupón",
    apply_coupon: "Aplicar cupón",
    coupon: "Cupón",
    credit_or_debit_card: "Tarjeta de crédito o débito",
    invalid_coupon: "Cupón inválido",
    applied_successfully: "Aplicado con éxito"
  },
  tax_info: {
    taxes: "(incl. {{percentage}}% de IVA)"
  },
  new_alert_component: {
    x_day_ago: "hace x días",
    x_days_ago: "hace x días",
    x_minute_ago: "hace x minutos",
    x_minutes_ago: "hace x minutos",
    x_hour_ago: "hace x horas",
    x_hours_ago: "hace x horas",
    x_second_ago: "hace x segundos",
    x_seconds_ago: "hace x segundos",
    x_week_ago: "hace una semana",
    x_weeks_ago: "hace x semanas",
    x_month_ago: "hace x meses",
    x_months_ago: "hace x meses",
    x_year_ago: "hace x años",
    x_years_ago: "hace x años"
  },
  summary_page_translations: {
    paypal_warning_msg: "Antes de poder realizar una reserva, el importe abierto debe ser pagado con PayPal.",
    paypal: "PayPal",
    paypal_paid_successfully: "El importe se ha pagado correctamente con PayPal. Ahora se puede reservar la cita.",
    paypal_paid_failed: "Lamentablemente, no se ha podido liquidar el importe con PayPal. Por favor, ponte en contacto con el soporte de Calenso.",
    or: "o",
    browser: "Navega por",
    choose_file: "Elige el archivo",
    clear: "Claro",
    meeting_type: "¿Dónde debe tener lugar la cita?",
    email: "Envía un correo electrónico a",
    sms: "SMS",
    both_sms_email: "Correo electrónico y SMS",
    notification_type: "Tipo de notificación",
    payment_title: "Pago",
    payment_subtitle: "Decide cómo quieres pagar tus servicios",
    payment_subtitle_sie: "Decide cómo quieres pagar tus servicios",
    summary_title: "Resumen",
    summary_subtitle: "Comprueba tu pedido y envíalo",
    summary_subtitle_sie: "Comprueba tu pedido y envíalo",
    payment_type: "Tipo de pago",
    creditcard: "Tarjeta de crédito",
    invoice: "Factura",
    store: "En el lugar",
    apply_coupon: "Me gustaría aplicar un código de descuento",
    choose_payment_method: "Elige el método de pago",
    tax: "incl. {{ rate }}% de IVA",
    subtotal: "Subtotal",
    personal_form_title: "Datos personales",
    personal_form_subtitle: "Cuéntanos algo sobre ti",
    personal_form_subtitle_sie: "Cuéntanos algo sobre ti",
    inclusive: "inclusivo",
    paymentMandatory: "El proveedor ha definido que el servicio debe pagarse online por adelantado. Por lo tanto, selecciona un método de pago.",
    file_upload: "Carga de archivos",
    file_upload_helper_text: "Se pueden adjuntar varios archivos",
    file_was_uploaded: "\"{{name}}\" se ha cargado con éxito",
    max_file_size_5mb: "El archivo subido es demasiado grande (se permite un máximo de 5MB).",
    malware_found: "Se produjo un problema al escanear el archivo en busca de virus (Acceso denegado). Por favor, contacta con el soporte técnico de Calenso.",
    multiple_file_upload_helper_text: "Se puede subir un máximo de 5 archivos. El archivo a subir no debe ser mayor de 5 MB.",
    single_file_upload_helper_text: "Se puede subir 1 archivo. El archivo a subir no debe ser mayor de 5 MB.",
    maximum_x_files: "Se puede subir un máximo de archivos {{fileLength}}.",
    document_invalid_file_type: "Tipo de archivo no válido. Solo se permiten archivos PDF y Excel.",
    image_invalid_file_type: "Tipo de archivo no válido, por favor carga un archivo de imagen válido.",
    request_sms_code: "Solicitar código",
    mobile_number_must_be_verified: "El número de móvil debe ser verificado para la reserva.",
    verification_code: "Código de verificación",
    sms_code_verification_failed_msg: "Lamentablemente, el código insertado no coincide con el código que enviamos por SMS.",
    check: "Comprueba",
    phone_number_verified_successfully: "El número de teléfono ha sido verificado con éxito.",
    resend_sms_helper_msg_1: "No he recibido ningún código.",
    resend_sms_helper_msg_2: "Vuelve a solicitar el código.",
    exceeded_sms_verification_limit_msg: "Este número de teléfono ha superado el número máximo de códigos de verificación. Ponte en contacto con el servicio de asistencia de Calenso (<a href=\"mailto:support@calenso.com\">support@calenso.com</a>).",
    salutation: "Saludo",
    mr: "Sr",
    ms: "Sra",
    other: "otros",
    select_payment_method: "Selecciona un motivo de pago",
    accept_data_policy: "Acepto y he leído <a href=\"https://calenso.com/agb/\" target=\"_blank\">Términos y Condiciones de Calenso</a> y <a href=\"https://calenso.com/datenschutz/\" target=\"_blank\">Política de Privacidad</a> para el uso de mis datos en relación con la reserva de la cita.",
    saferpay_payment_overlay: "Esta pantalla se ocultará automáticamente una vez que el pago se haya completado o cancelado.<br/>En caso de que la pantalla de pago se haya cerrado accidentalmente, pulse el botón de cierre para ocultarla y volver a iniciar el pago.",
    lexoffice: "Factura (LexOffice)",
    file_uploading: "La carga del archivo aún está en curso. Por favor, espere a que finalice la acción e intente reservar de nuevo.",
    saferpay: "Saferpay",
    saferpay_pay: "Toque para pagar con Saferpay",
    payment_success_title: "Pago correcto",
    payment_success_message: "El importe pendiente se ha pagado correctamente con {{ paymentMethod }}. Ahora se puede reservar la cita. Si no hay reserva, no se cargará ningún importe.",
    title: "Título",
    country: "País",
    title_aria_describedby: "Título especial que admite Dr., Dr. med., Prof.",
    salutation_aria_describedby: "Forma de saludo que admite Sr., Sra. y otros",
    prename_aria_describedby: "Nombre o primer nombre",
    lastname_aria_describedby: "Apellido",
    notification_type_aria_describedby: "Para definir en el proceso de reserva cómo desea ser notificado sobre la reserva.",
    email_describedby: "Correo electrónico",
    phoneNumber_aria_describedby: "Número de teléfono",
    smsPhoneCode_aria_describedby: "OTP para verificar el número de teléfono",
    mobileNumber_aria_describedby: "Número de móvil",
    smsMobileCode_aria_describedby: "OTP para verificar el número de móvil",
    street_aria_describedby: "Dirección",
    zip_aria_describedby: "Código postal",
    city_aria_describedby: "Ciudad",
    country_aria_describedby: "País",
    comment_aria_describedby: "Comentarios adicionales si los tiene",
    internal_comment_aria_describedby: "Comentario interno (solo para uso interno)",
    company_aria_describedby: "Nombre de la empresa",
    captcha_aria_describedby: "Captcha"
  },
  buttons: {
    yes_search: "Sí, busca.",
    remove: "Elimina"
  },
  add: "Añade"
}
