export const nl_NL = {
  customer_type: 'Type klant',
  manage_appointment_now: 'Afspraak wijzigen',
  maintenance_page_title: 'Calenso wordt verbeterd voor jou',
  maintenance_page_subtitle: 'Calenso wordt bijgewerkt naar de nieuwste versie. Kom over een paar minuten terug.',
  maintenance_page_button: 'Naar de statuspagina',
  load_more: 'Meer laden',
  show_more: 'Toon Meer',
  internal_label: 'Intern',
  check_availability_for_anyone: 'Controleer de beschikbaarheid voor iedereen',
  store_not_found: 'Er is geen toonzaal gevonden',
  appointment: 'Afspraken',
  event: 'Groepsafspraken',
  resource: 'Middelen',
  morgen: 'ochtend',
  vormittag: 'voormiddag',
  mittag: 'middag',
  nachmittag: 'namiddag',
  abend: 'avond',
  nacht: 'nacht',
  free: 'gratis',
  hoursShort: 'u',
  minutesShort: 'm',
  mondayLong: 'maandag',
  day1: 'Ma',
  tuesdayLong: 'Dinsdag',
  day2: 'Di',
  wednesdayLong: 'Woensdag',
  day3: 'Wo',
  thursdayLong: 'Donderdag',
  day4: 'Do',
  fridayLong: 'Vrijdag',
  day5: 'Vr',
  saturdayLong: 'Zaterdag',
  day6: 'Za',
  sundayLong: 'Zondag',
  day0: 'Zo',
  Januar: 'Januari',
  Februar: 'Februari',
  März: 'Maart',
  April: 'April',
  Mai: 'Mei',
  Juni: 'Juni',
  Juli: 'Juli',
  August: 'Augustus',
  September: 'September',
  Oktober: 'Oktober',
  November: 'November',
  Dezember: 'December',
  "shortMonths": {
    "Januar": "Jan",
    "Februar": "Feb",
    "März": "Mrt",
    "April": "Apr",
    "Mai": "Mei",
    "Juni": "Jun",
    "Juli": "Jul",
    "August": "Aug",
    "September": "Sep",
    "Oktober": "Okt",
    "November": "Nov",
    "Dezember": "Dec"
  },
  du: 'jij',
  sie: 'Jij',
  havingFormDu: 'hebben',
  havingFormSie: 'hebben',
  yes: "Ja",
  no: "Geen",
  confirm: {
    title: 'Bevestig bestelling',
    confirmationText: 'U heeft {{ cartLength }} afspraken in uw winkelwagen. Wilt u ze nu allemaal boeken?',
    confirmationTextSie: 'U heeft {{ cartLength }} afspraken in uw winkelwagen. Wilt u ze nu allemaal boeken?',
    ok: 'Boek',
    cancel: 'Annuleren'
  },
  internal: {
    search_customer: 'Bestaande klant zoeken en formulier vooraf invullen',
    selectPlaceholder: 'Zoek klant',
    customerDetails: 'Klantgegevens',
    noCustomer: 'Er zijn geen klanten geconfigureerd',
    create_customer: 'Klant aanmaken'
  },
  common: {
    report_bug: 'Bug rapporteren',
    subscription_error_title: 'Geen abonnement!',
    subscription_error_desc: 'Sorry, er kon geen abonnement worden gevonden. Neem contact op met Calenso Support.',
    next: 'Volgende',
    previous: 'Vorige',
    reset: 'Reset applicatie',
    resetSuccess: 'De boekingssoftware werd met succes gereset. Gegevens die tijdelijk waren opgeslagen zijn verloren.',
    loading: 'Laden...',
    durationHour: 'u',
    durationMinute: 'm',
    clock: 'u',
    disclaimer: 'Deze dienst wordt u aangeboden door <a href=\'https://www.calenso.com\' class=\'cal-link\' target=\'_blank\'>Calenso</a>.',
    book: 'Boek',
    choseBookType: 'Wat zou je willen boeken?',
    company: 'Bedrijf',
    prename: 'Voornaam',
    lastname: 'Achternaam',
    email: 'E-mail',
    phone: 'Telefoon',
    address: 'Adres',
    createAccount: 'Klantaccount aanmaken (gebruikersaccount met alle registraties)',
    noDateError: 'De gewenste afspraak is door iemand anders geboekt, ga terug en selecteer een nieuwe afspraak.',
    devNotice: 'De reserveringswidget is verbonden met het DEV backend systeem. Wees voorzichtig!',
    store_access_warning: 'De toegang tot het geheugen van de browser is beperkt vanwege uw privacy-instellingen. Niet alle functies van de boekingswidget zijn mogelijk beschikbaar.',
    save: "Opslaan",
    cancel: "Annuleren",
    additionalParticipantsUpTo: "Je kunt maximaal {{ number }} gasten toevoegen",
    additionalGuest: 'Extra deelnemers',
    addAdditionalGuest: 'Deelnemers toevoegen',
    additionalGuestCartLabel: '{{ number }} extra gast',
    additionalGuestsCartLabel: '{{ number }} extra gasten',
    widget_disabled: 'De reserveringswidget is momenteel uitgeschakeld',
    widget_disabled_description: 'De reserveringswidget is tijdelijk uitgeschakeld. Neem contact op met de Calenso support (Error-Code: PMT-01).',
    no_workers_widget_disabled_description: 'Er kunnen momenteel geen afspraken worden geboekt omdat geen resource is ingeschakeld.',
    workers_not_available: 'Geen boekbare dienst gevonden',
    workers_not_available_description: 'Er is geen boekbaar type afspraak geregistreerd. Zorg ervoor dat ten minste één type geselecteerd is en toegewezen is aan een boekbare medewerker. Het invoeren van een type afspraak alleen is niet voldoende om te kunnen boeken.',
    price_from_label: 'van',
    close: 'Sluiten',
    configuration_error: 'Configuratiefout',
    resource_is_not_bookable: 'De geselecteerde bron is niet boekbaar.',
    verification_token_missing: 'De reserveringswidget wordt in de interne modus gebruikt en vereist daarom een verificatietoken. Neem contact op met Calenso support.',
    invalid_verification_code: 'Toegang tot de widget in interne modus vereist een geldige token, zorg ervoor dat de token geldig is en probeer het opnieuw.',
    internal_appointment_booking_widget_disabled: "De interne widget-service is uitgeschakeld door de partner. Neem contact op met je systeembeheerder voor meer info.",
    external_appointment_booking_widget_disabled: "De widget-service is uitgeschakeld door onze partner. Neem contact met hen op voor meer info.",
    external_event_booking_widget_disabled: "De widget-service is uitgeschakeld door onze partner. Neem contact met hen op voor meer info.",
    conference_invalid_link: "Het lijkt erop dat de verstrekte conferentielink ongeldig is. Controleer de link en probeer het opnieuw.",
    conference_expired_link: "Het spijt ons, maar deze conferentielink is verlopen. Neem contact op met de organisator voor verdere assistentie.",
    conference_not_belonging: "Oeps, het lijkt erop dat deze conferentie aan een andere partner is gekoppeld.",
    conference_booked: "Oeps, het lijkt erop dat deze conferentielink al is gebruikt en geboekt. Als je vragen hebt of hulp nodig hebt, neem gerust contact op met de organisatoren.",
    conference_invalid_configurations: "De conferentie is niet beschikbaar vanwege ongeldige interne configuraties. Neem contact op met de organisator voor verdere hulp.",
    location: "Locatie",
    empty_string_validation: "Alleen spaties zijn niet toegestaan.",
    invalid_password: "Voer een geldig wachtwoord in."
  },
  appointments: {
    step1: {
      distance_from_store: '{{ distance }} km afstand',
      search_store_postcode_helper_text: 'Voer postcode in om de dichtstbijzijnde toonzaal te zoeken',
      search_services_helper_text: 'Voer een zoekterm in om naar types afspraken te zoeken',
      title: 'Types afspraken',
      chooseStore: 'Selecteer toonzaal',
      chooseService: 'Kies type afspraak',
      nextButton: 'Volgende',
      search_store_by_postcode_placeholder: 'Voer postcode in om de juiste toonzaal te zoeken',
      create_lead: 'Opportunity MH',
      expert_cap_button_label: 'Toewijzen aan een deskundige CAP',
      appointment_booking_not_available_for_zipcode: 'Sorry, het boeken van een afspraak is niet beschikbaar voor deze postcode. Maak een nieuwe klant aan, zodat een CAP telefonisch contact kan opnemen.',
      price_from_label: 'van'
    },
    step2: {
      title: 'Medewerker',
      chooseEmployee: 'Kies een vrije medewerker',
      name: 'Naam',
      function: 'Functie',
      noSpecificWorker: 'Geen voorkeur',
      noSpecificFunction: 'Alle functies',
      exception: "Uitzondering voor werknemer definiëren",
      nextButton: 'Kies datum/tijd'
    },
    step3: {
      title: 'Datum',
      chooseDate: 'Kies de gewenste datum',
      morning: 'Ochtend',
      forenoon: 'Voormiddag',
      noon: 'Middag',
      afternoon: 'Namiddag',
      evening: 'Avond',
      night: 'Nacht',
      infoMsg: 'Het is mogelijk om meerdere afspraken te selecteren. Kies  de afspraken die u wilt via checkbox. De afspraken worden dan toegevoegd aan de winkelwagen.',
      appointment: 'Afspraak',
      employee: 'Medewerker',
      proposals: 'Vrije afspraken',
      noFreeAppointments: 'Helaas zijn er die dag geen vrije afspraken!',
      nextButton: 'Volgende',
      cartNotSupported: 'Deze aanbieder ondersteunt slechts één registratie per boeking.',
      cartSupported: 'Deze provider ondersteunt meerdere registraties per boeking.',
      workerSelectLable: "Persoonlijke Adviseur Selecteren:",
      workerSelectLinkPrefix: "Geen afspraak gevonden met uw adviseur?",
      workerSelectShowAllLinkPostfix: "Toon alle adviseurs",
      workerSelectHideAllLinkPostfix: "Alle adviseurs verbergen",
      troubleshooting_mode_note_pre: "Mist er een tijdslot? Probeer ",
      troubleshooting_mode_link: "de probleemoplossingsmodus",
      troubleshooting_mode_note_post: " en krijg meer informatie over niet-beschikbare tijdsloten.",
      turn_off_troubleshooting: "Uitschakelen",
      common_notes: "Algemene notities"
    },
    step4: {
      comment_helper_text: 'Maximaal 2000 tekens',
      title: 'Afwerking',
      summary: 'Samenvatting',
      company: 'Bedrijf',
      store: 'Toonzaal',
      employee: 'Medewerker',
      bookedServices: 'Type afspraak',
      duration: 'Duur',
      price: 'Prijs',
      finalPrice: 'Kosten',
      total: 'Totaal',
      yourData: 'Uw gegevens',
      disclaimer: 'Gelieve uw persoonlijke contactgegevens in te vullen. Alle velden met een * zijn verplicht.',
      prename: {
        label: 'Voornaam',
        placeholder: 'Voornaam',
        error: {
          minChars: 'De voornaam moet ten minste {{ requiredLength }} tekens bevatten.',
          required: 'Vul een voornaam in.'
        }
      },
      lastname: {
        label: 'Achternaam',
        placeholder: 'Achternaam',
        error: {
          minChars: 'De achternaam moet ten minste {{ requiredLength }} tekens bevatten.',
          required: 'Vul alstublieft een achternaam in.'
        }
      },
      phone: {
        label: 'Mobiele telefoon',
        placeholder: '+324xxxxxxxx',
        error: {
          pattern: 'Het telefoonnummer kan alleen cijfers bevatten.',
          minChars: 'Het telefoonnummer moet tenminste {{ requiredLength }} cijfers bevatten.',
          required: 'Gelieve een telefoonnummer in te vullen.'
        }
      },
      eMail: {
        label: 'E-mail',
        error: {
          pattern: 'Vul een geldig e-mail adres in.',
          minChars: 'Het email adres moet tenminste {{ requiredLength }} tekens bevatten.',
          required: 'Gelieve een e-mail adres in te vullen.'
        }
      },
      message: {
        label: 'Bericht',
        placeholder: 'placeholder'
      },
      internal_comment: {
        label: 'Intern commentaar',
        placeholder: 'Voeg opmerkingen toe voor intern gebruik',
        comment_helper_text: 'Maximaal 5000 tekens.'
      },
      company_name: {
        label: 'Bedrijfsnaam',
        error: {
          minChars: 'Dit veld vereist ten minste {{ requiredLength }} tekens.'
        }
      },
      customFields: {
        defaultOption: 'Kies een optie',
        error: {
          maxChars: 'Dit veld staat maximaal {{ requiredLength }} tekens toe.',
          minChars: 'Dit veld vereist ten minste {{ requiredLength }} tekens.',
          required: 'Dit veld is verplicht.',
          questionisRequired: "{{ text }} is verplicht",
          checkboxisRequired: "Dit selectievakje moet worden geselecteerd om door te gaan."
        }
      },
      cart: {
        emptyCartWarning: 'Er zijn momenteel geen afspraken in uw winkelwagen.',
        removeIcon: 'Afspraak uit winkelwagentje verwijderen.',
        waiting_list: 'Wachtlijst.'
      },
      numberSelect: {
        increase: 'Waarde verhogen',
        decrease: 'Waarde verlagen'
      }
    },
    finalpage: {
      successTitle: 'U heeft de afspraak(en) met succes geboekt',
      successMessageDu: 'De afspraakreservering werd met succes verzonden. Er is een bevestiging verzonden via e-mail of via SMS. <br> <br> Als je om wat voor reden dan ook niet aanwezig kunt zijn, annuleer dan je afspraak via de annuleringslink in de e-mail of sms.',
      successMessageSie: 'De afspraakreservering werd met succes verzonden. Er is een bevestiging verzonden via e-mail of via SMS. <br> <br> Als je om wat voor reden dan ook niet aanwezig kunt zijn, annuleer dan je deelname via de annuleringslink in de e-mail of sms.',
      errorTitle: 'Er is een fout opgetreden.',
      errorMessage: 'De datum kon niet geboekt worden. Gelieve opnieuw te proberen.',
      errorMessageDu: 'Sorry, de afspraak kon niet worden geboekt. Probeer het opnieuw.',
      errorMessageSie: 'Sorry, de afspraak kon niet worden geboekt. Probeer het opnieuw.',
      bookNextAppointment: 'Boek nog een afspraak',
      bookAppointmentAgain: 'Ga terug'
    },
    error: {
      noworkers: 'Helaas heeft deze aanbieder nog geen medewerkers gedefinieerd die geboekt kunnen worden.'
    }
  },
  events: {
    list: "Lijst",
    grid: "Rooster",
    noEvents: "Deze aanbieder heeft nog geen evenementen opgegeven.",
    hidden_events_message: "De gebeurtenissen van deze partner zijn verborgen. Gebruik de link naar het evenement dat u van de partner hebt ontvangen om u te registreren.",
    filter_result_null: "Er zijn geen resultaten die aan je criteria voldoen.",
    step1: {
      title: "Evenementen",
      moreInformation: "Meer informatie",
      noFreeSlots: "Geen vrije tijdslots meer",
      noEvents: "Er werden geen evenementen georganiseerd.",
      showDescription: "Toon volledige omschrijving",
      hideDescription: "Verberg omschrijving",
      book: "Boek",
      noPlaceDefined: "Geen locatie bepaald",
      store_filter: "Opslagfilter",
      category_filter: "Categoriefilter",
      all_categories: "Alle"
    },
    step2: {
      title: 'Datum',
      slots: 'Tijdslot',
      chooseDate: 'Kies de gewenste datum',
      booked: 'Geboekt',
      capacity: 'Beschikbaar',
      noFreeDates: 'Er zijn geen vrije tijdslots voor deze partner',
      bookedOut: 'Dit slot is al volgeboekt',
      cartNotSupported: 'Deze aanbieder ondersteunt slechts één tijdslot per boeking.',
      cartSupported: 'Deze provider ondersteunt meerdere registraties per boeking.',
      alreadyBookedByUser: 'U heeft dit tijdslot al gereserveerd. In geval van problemen gelieve de applicatie te resetten.',
      waitinglist: 'wachtlijst: {{ capacity }} vrij',
      fully_booked: "Volgeboekt",
      regular_count_singular: '<b>1</b> vrije plaats',
      regular_count_plural: '<b>{{ number }}</b> vrije plaatsen',
      waiting_count_singular: "1 vrije wachtlijstplek",
      waiting_count_plural: "{{ number }} vrije wachtlijstplekken",
      free_seats: 'Gratis plaatsen',
      free_waiting_seats: 'Gratis wachtlijstplaatsen',
      multiday_group_appointment: "Dit is een meerdaagse groepsafspraak"
    },
    step3: {
      title: 'Bevestiging',
      summary: 'Samenvatting',
      company: 'Bedrijf',
      store: 'Toonzaal',
      employee: 'Medewerker',
      bookedEvent: 'Afspraak',
      date: 'Datum',
      time: 'Tijd',
      price: 'Prijs',
      finalPrice: 'Kosten',
      total: 'Totaal',
      yourData: 'Uw gegevens',
      disclaimer: 'Gelieve uw contactgegevens in te vullen. Alle velden met een * zijn verplicht.',
      cart: {
        emptyCartWarning: 'Er zijn momenteel geen afspraken in de winkelwagen.',
        waiting_list: 'Wachtlijst'
      }
    },
    finalpage: {
      successTitle: 'De registratie werd met succes ingediend.',
      successMessageDu: 'De registratie voor het evenement werd met succes ingediend. Er is een bevestiging verzonden via e-mail of via SMS. <br> <br> Als je het evenement om wat voor reden dan ook niet kunt bijwonen, gebruik dan de annuleringslink in de e-mail of sms om je deelname te annuleren.',
      successMessageSie: 'De registratie voor het evenement werd met succes ingediend. Er is een bevestiging verzonden via e-mail of via SMS. <br> <br> Als je het evenement om wat voor reden dan ook niet kunt bijwonen, gebruik dan de annuleringslink in de e-mail of sms om je deelname te annuleren.',
      errorTitle: 'Er is een fout opgetreden.',
      errorMessage: 'Er is een fout opgetreden tijdens de registratie. Gelieve opnieuw te proberen.',
      bookNextEvent: 'Boek volgende registratie',
      bookEventAgain: 'Ga terug.'
    }
  },
  resources: {
    noResources: 'Deze provider heeft nog geen medewerkers opgegeven.',
    step1: {
      title: 'Medewerkers',
      chooseResource: 'Kies medewerker'
    },
    step2: {
      title: 'Datum',
      startDate: 'Startdatum: ',
      endDate: 'Einddatum: ',
      startTime: 'Selecteer starttijd',
      endTime: 'Selecteer eindtijd',
      resetCalendar: 'Kalender resetten',
      noFreeResources: 'Geen boekbare medewerkers gevonden voor dit datumbereik',
      selectDate: 'Selecteer datum of datumbereik'
    },
    step3: {
      title: 'Beëindig',
      summary: 'Samenvatting',
      company: 'Bedrijf',
      store: 'Toonzaal',
      employee: 'Medewerker',
      bookedEvent: 'Afspraak',
      date: 'Datum',
      time: 'Tijd',
      price: 'Prijs',
      finalPrice: 'Kosten',
      total: 'Totaal',
      yourData: 'Uw gegevens',
      disclaimer: 'Gelieve uw contactgegevens in te vullen. Alle velden met een * zijn verplicht.',
      prename: {
        label: 'Voornaam',
        placeholder: 'Voornaam',
        error: {
          minChars: 'De voornaam moet ten minste {{ requiredLength }} tekens lang zijn.',
          required: 'Gelieve uw voornaam te vermelden.'
        }
      },
      lastname: {
        label: 'Achternaam',
        placeholder: 'Achternaam',
        error: {
          minChars: 'De achternaam moet ten minste {{ requiredLength }} tekens lang zijn.',
          required: 'Vul alstublieft uw achternaam in.'
        }
      },
      phone: {
        label: 'Mobiele telefoon',
        placeholder: '+324xxxxxxxx',
        error: {
          pattern: 'Het telefoonnummer mag alleen nummers bevatten.',
          minChars: 'Het telefoonnummer moet ten minste {{ requiredLength }} tekens bevatten.',
          required: 'Voer een telefoonnummer in.'
        }
      },
      eMail: {
        label: 'e-Mail',
        error: {
          pattern: 'Voer een geldig e-mail adres in.',
          minChars: 'Het e-mail adres moet minstens {{ requiredLength }} tekens lang zijn.',
          required: 'Voer een e-mail adres in.'
        }
      },
      message: {
        label: 'Bericht',
        placeholder: 'placeholder'
      },
      customFields: {
        defaultOption: 'Kies een optie',
        error: {
          minChars: 'Dit veld vereist {{ requiredLength }} tekens.',
          required: 'Dit veld is verplicht.'
        }
      },
      cart: {
        emptyCartWarning: 'Er zijn momenteel geen medewerkers in de winkelwagen.'
      },
      From: 'Van',
      To: 'Naar'
    },
    finalpage: {
      successTitle: 'U heeft met succes medewerker(s) geboekt',
      successMessageDU: 'In het geval dat u van gedachten verandert, annuleer dan uw geboekte afspraak ten minste {{ leadTime }} uur voor het geboekte tijdslot.',
      successMessageSIE: 'In het geval dat u van gedachten verandert, annuleer dan uw geboekte afspraak ten minste {{ leadTime }} uur voor het geboekte tijdslot.',
      errorTitle: 'Er is een fout opgetreden.',
      errorMessage: 'De medewerker kon niet geboekt worden. Gelieve opnieuw te proberen.',
      bookNextEvent: 'Extra medewerkers boeken',
      bookEventAgain: 'Ga terug.'
    }
  },
  customer: {
    prename: {
      label: 'Voornaam',
      placeholder: 'Voornaam',
      error: {
        minChars: 'De voornaam moet ten minste {{ requiredLength }} karakters lang zijn.',
        required: 'Voer uw voornaam in.'
      }
    },
    lastname: {
      label: 'Achternaam',
      placeholder: 'Achternaam',
      error: {
        minChars: 'De achternaam moet ten minste {{ requiredLength }} karakters lang zijn.',
        required: 'Vul uw achternaam in.'
      }
    },
    phone: {
      label: 'Telefoonnummer',
      placeholder: '+324........',
      error: {
        pattern: 'Het telefoonnummer mag alleen cijfers bevatten.',
        minChars: 'Het telefoonnummer moet ten minste {{ requiredLength }} nummers bevatten.',
        required: 'Voer uw telefoonnummer in.',
        invalid: 'Voer een geldig mobiel nummer in.'
      }
    },
    eMail: {
      label: 'E-mail',
      error: {
        pattern: 'Voer een geldig e-mail adres in.',
        minChars: 'Het e-mailadres moet ten minste {{ requiredLength }} karakters bevatten.',
        required: 'Een e-mail adres is vereist.'
      }
    },
    street: {
      label: 'Straat / Nr.',
      placeholder: 'Straatnaam',
      error: {
        minChars: 'De straat moet ten minste {{ requiredLength }} tekens bevatten.',
        required: 'Voer uw straatnaam in.'
      }
    },
    zip: {
      label: 'ZIP',
      placeholder: 'Postcode',
      error: {
        minChars: 'Het postnummer moet ten minste {{ requiredLength }} tekens bevatten.',
        required: 'Voer uw postcode in.',
        pattern: "Voer een geldige postcode in, alsjeblieft.",
      }
    },
    city: {
      label: 'Stad',
      placeholder: 'Naam van de stad',
      error: {
        minChars: 'De stad moet ten minste {{ requiredLength }} tekens bevatten.',
        required: 'Voer uw plaatsnaam in.'
      }
    },
    message: {
      label: 'Bericht',
      placeholder: 'placeholder'
    },
    customFields: {
      defaultOption: 'Kies een optie',
      error: {
        minChars: 'Dit veld vereist ten minste {{ requiredLength }} karakters.',
        required: 'Dit veld is verplicht.'
      }
    },
    captcha: {
      required: 'Dit veld is verplicht.',
      invalid: 'Voer een geldige captcha in.',
      valid: 'Captcha succesvol toegepast.',
      placeholder: 'Type de tekst hierboven'
    }
  },
  stripe: {
    stripe_title: 'Betaal uw volgende bezoek online',
    submit_payment: 'Betaling indienen',
    stripe_label: 'Kredietkaart of debetkaart',
    online_payment_optional: 'In deze stap, heeft u de mogelijkheid om uw volgende bezoek nu te regelen. Er zijn geen extra kosten voor u. De betaling gebeurt via een beveiligde provider (Stripe). Wij slaan geen credit card informatie op onze servers op.',
    online_payment_mandatory: 'Om de reservering af te ronden, verzoeken wij u vriendelijk om uw volgende bezoek nu te betalen. Er zijn geen extra kosten voor u. De betaling geschiedt via een beveiligde provider (Stripe). Wij slaan geen credit card informatie op onze servers op.'
  },
  alert_messages: {
    select_day_in_calendar_msg: "Selecteer een specifieke dag in de kalender om mogelijke data voorgesteld te krijgen.",
    no_events_in_month: "Helaas zijn er geen vrije tijdslots meer in de geselecteerde maand.",
    no_events: "Helaas, dit evenement heeft geen boekbare tijdslots in de toekomst. Daarom wordt er geen kalender weergegeven om een datum te selecteren.",
    waitinglist_enabled_message: "Sommige tijden zijn al volgeboekt. Inschrijving op de wachtlijst is echter mogelijk. Zodra een deelname wordt geannuleerd, schuift de volgende wachtlijstplaats op en ontvangt deze een bevestiging van deelname.",
    waitinglist_booking_message: "Hartelijk dank! We hebben uw reservering succesvol geaccepteerd. Aangezien u nu op de wachtlijst staat voor ten minste één afspraak, ontvangt u van ons alleen een bevestiging van deelname als een andere deelnemer zijn afspraak niet kan bijwonen. In dat geval wordt u per e-mail op de hoogte gebracht. Helaas kunt u niet deelnemen aan de afspraak zonder een officiële bevestiging van onze site.",
    window_is_fully_booked: "Dit tijdslot is volgeboekt (inclusief wachtlijst).",
    singular_waitinglist_is_available: "Dit tijdslot is volgeboekt, maar er is nog <b>{{ number }}</b> vrije plek op de wachtlijst.",
    plural_waitinglist_is_available: "Dit tijdslot is volgeboekt, maar er zijn nog <b>{{ number }}</b> vrije plekken op de wachtlijst.",
    no_bookable_dates_message: "Helaas zijn er geen boekbare dagen meer in de maand {{ month }}. Moeten we zoeken naar vrije data in toekomstige maanden?",
    no_slots_dates_message: "Helaas zijn er geen tijdslots meer te boeken in de maand {{ month }}. Moeten we in toekomstige maanden zoeken naar vrije tijdslots?",
    no_slots_dates_reached_message: "Helaas zijn er geen tijdslots meer te boeken in de maand {{ month }}. De maximale boekingsperiode is bereikt.",
    no_slots_years_reached_message: "Helaas zijn er geen boekbare tijdslots meer beschikbaar in het jaar {{ year }}. De maximale boekingsperiode is bereikt.",
    no_bookable_dates_reached_message: "Helaas zijn er geen boekbare data meer in de maand {{ month }}. De maximale boekingsperiode is bereikt.",
    xss_error_message: "Deze tekst bevat niet-ondersteunde HTML-tags.",
    slot_already_reserved_by_other_error: "In de tussentijd is de tijd die je probeerde te reserveren door iemand anders geboekt. Kies een andere tijd en probeer het later opnieuw.",
    failed_to_reserve_slot_error: "Er is iets misgegaan tijdens het reserveren van de tijd voor jou. Probeer het later nog eens!",
    reserved_by_someone: "Gereserveerd door iemand anders"
  },
  coupon_credit_card_info: {
    redeem_coupon_1: 'Coupon inwisselen',
    apply_coupon: 'Coupon toepassen',
    coupon: 'Coupon',
    credit_or_debit_card: 'Krediet- of debetkaart',
    invalid_coupon: 'Ongeldige coupon',
    applied_successfully: 'Met succes toegepast'
  },
  tax_info: {
    taxes: '(incl. {{percentage}}% BTW)'
  },
  new_alert_component: {
    x_day_ago: 'x dag geleden',
    x_days_ago: 'x dagen geleden',
    x_minute_ago: 'x minuut geleden',
    x_minutes_ago: 'x minuten geleden',
    x_hour_ago: 'x uur geleden',
    x_hours_ago: 'x uren geleden',
    x_second_ago: 'x seconde geleden',
    x_seconds_ago: 'x seconden geleden',
    x_week_ago: 'x week geleden',
    x_weeks_ago: 'x weken geleden',
    x_month_ago: 'x maand geleden',
    x_months_ago: 'x maanden geleden',
    x_year_ago: 'x jaar geleden',
    x_years_ago: 'x jaren geleden'
  },
  summary_page_translations: {
    paypal_warning_msg: 'Voordat een boeking mogelijk is, moet het openstaande bedrag met PayPal worden betaald.',
    paypal: 'PayPal',
    saferpay_payment_overlay: 'Dit scherm wordt automatisch verborgen zodra de betaling is voltooid of geannuleerd.<br/>Indien het betalingsscherm per ongeluk gesloten wordt, tik dan op de sluitknop om het te verbergen en de betaling opnieuw te initiëren.',
    or: 'of',
    browser: 'Bladeren',
    choose_file: 'Kies bestand',
    clear: 'Duidelijk',
    meeting_type: 'Waar moet de afspraak plaatsvinden?',
    email: 'E-mail',
    sms: 'SMS',
    both_sms_email: 'E-mail en SMS',
    notification_type: 'Type kennisgeving',
    payment_title: 'Betaling',
    payment_subtitle: 'Beslis hoe u voor uw diensten wilt betalen',
    payment_subtitle_sie: 'Beslis hoe u voor uw diensten wilt betalen',
    summary_title: 'Samenvatting',
    summary_subtitle: 'Controleer uw bestelling en verzend ze',
    summary_subtitle_sie: 'Controleer uw bestelling en verzend ze',
    payment_type: 'Type betaling',
    creditcard: 'Kredietkaart',
    invoice: 'Factuur',
    lexoffice: 'Factuur (LexOffice)',
    store: 'Ter plaatse',
    apply_coupon: 'Ik wil graag een kortingscode toepassen',
    choose_payment_method: 'Kies betalingsmethode',
    tax: 'incl. {{ percentage }}% BTW',
    subtotal: 'Subtotaal',
    personal_form_title: 'Persoonsgegevens',
    personal_form_subtitle: 'Vertel ons iets over jou',
    personal_form_subtitle_sie: 'Vertel ons iets over jou',
    inclusive: 'inclusief',
    paymentMandatory: 'De provider heeft bepaald dat de dienst online vooraf betaald moet worden. Selecteer daarom een betalingsmethode.',
    file_upload: 'Bestand uploaden',
    file_upload_helper_text: 'Meerdere bestanden kunnen worden bijgevoegd',
    file_was_uploaded: '"{{name}}" werd met succes geüpload',
    max_file_size_5mb: 'Het geüploade bestand is te groot (maximaal 5MB is toegestaan).',
    malware_found: 'Er is een probleem opgetreden bij het scannen van het bestand op virussen (Toegang geweigerd). Neem contact op met de ondersteuning van Calenso!',
    multiple_file_upload_helper_text: 'Er kunnen maximaal 5 bestanden worden geüpload. Het te uploaden bestand mag niet groter zijn dan 5 MB.',
    single_file_upload_helper_text: 'Er kan 1 bestand worden geüpload. Het te uploaden bestand mag niet groter zijn dan 5 MB.',
    file_uploading: 'Het uploaden van het bestand is nog bezig. Wacht tot de actie is voltooid en probeer dan opnieuw te boeken.',
    maximum_x_files: 'Er kunnen maximaal {{fileLength}} bestanden worden geüpload.',
    document_invalid_file_type: "Ongeldig bestandstype. Alleen PDF- en Excel-bestanden zijn toegestaan.",
    image_invalid_file_type: "Ongeldig bestandstype, upload alstublieft een geldig afbeeldingsbestand.",
    request_sms_code: 'Aanvraag code',
    mobile_number_must_be_verified: 'Het nummer moet worden geverifieerd voor de boeking.',
    verification_code: 'Verificatie code',
    sms_code_verification_failed_msg: 'De ingevoegde code komt helaas niet overeen met de code die wij per SMS hebben verzonden.',
    check: 'Check',
    phone_number_verified_successfully: 'Het nummer is succesvol geverifieerd.',
    resend_sms_helper_msg_1: 'Ik heb geen code ontvangen.',
    resend_sms_helper_msg_2: 'Vraag de code opnieuw aan.',
    resend_sms_successfully: 'SMS code opnieuw verzonden',
    exceeded_sms_verification_limit_msg: 'Dit nummer heeft het maximum aantal verificatiecodes overschreden. Neem contact op met Calenso Support (<a href="mailto:support@calenso.com">support@calenso.com</a>).',
    salutation: 'Gegroet',
    mr: 'Mr',
    ms: 'Mevrouw',
    other: 'andere',
    select_payment_method: 'Gelieve eerst het openstaande bedrag via PayPal te betalen, voordat u de boeking kunt indienen.',
    accept_data_policy: 'Ik ga akkoord met en heb kennis genomen van <a href="https://calenso.com/agb/" target="_blank">Calenso\'s Algemene Voorwaarden</a> & <a href="https://calenso.com/datenschutz/" target="_blank">Privacybeleid</a> voor het gebruik van mijn gegevens in verband met de boeking van de afspraak.',
    saferpay: 'Saferpay',
    saferpay_pay: 'Tik om te betalen met Saferpay',
    payment_success_title: 'Succesvolle betaling',
    payment_success_message: 'Het openstaande bedrag werd succesvol betaald met {{ paymentMethod }}. De afspraak kan nu geboekt worden. Als er geen boeking is, wordt er geen bedrag afgeschreven.',
    title: 'Titel',
    country: 'Land',
    title_aria_describedby: "Speciale titel die Dr., Dr. med., Prof. ondersteunt",
    salutation_aria_describedby: "Aanhefvorm die de heer, mevrouw en anderen ondersteunt",
    prename_aria_describedby: "Voornaam of roepnaam",
    lastname_aria_describedby: "Achternaam",
    notification_type_aria_describedby: "Om in het boekingsproces te kunnen definiëren hoe u op de hoogte wilt worden gesteld van de boeking.",
    email_describedby: "E-mail",
    phoneNumber_aria_describedby: "Telefoonnummer",
    smsPhoneCode_aria_describedby: "OTP om telefoonnummer te verifiëren",
    mobileNumber_aria_describedby: "Mobiel nummer",
    smsMobileCode_aria_describedby: "OTP om mobiel nummer te verifiëren",
    street_aria_describedby: "Adres",
    zip_aria_describedby: "Postcode",
    city_aria_describedby: "Stad",
    country_aria_describedby: "Land",
    comment_aria_describedby: "Extra opmerkingen als u die heeft",
    internal_comment_aria_describedby: "Interne opmerking (alleen voor intern gebruik)",
    company_aria_describedby: "Bedrijfsnaam",
    captcha_aria_describedby: "Captcha"
  },
  buttons: {
    yes_search: 'Ja, zoek.',
    remove: 'Verwijder'
  },
  add: 'Toevoegen',
  compact: {
    no_service: 'Er kunnen momenteel geen afspraken worden geboekt omdat er geen overeenkomstige dienst is gevonden.',
    navigation: {
      date: 'Datum',
      date_slots: 'Datum en tijdstip',
      personal_info: 'Persoonlijke gegevens'
    }
  }
};
